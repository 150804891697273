















































import '../scss/index.scss';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Button } from '@frontiers-vue/button';
import axios, { AxiosResponse, AxiosError } from 'axios';
import Configuration from '../core/Configuration';
import { IUnsubscribedData } from '../core/models';
// import Configuration from '@/core/Configuration';

@Component({
  components: { Button },
})
export default class GlobalUnsubscribe extends Vue {
  private isPendingToConfirm: boolean = true;
  private isUnsubscribing: boolean = false;
  private isResubscribing: boolean = false;
  private subscriptionTypeId: number = 0;
  private subscriptionType: string = '';
  private resourceValue: string = '';
  private resourceId: string = '';
  private email: string = '';
  private errorMessage: string = '';
  private preferencesKey: string = '';
  private urlKey: string = '';
  private urlReason: string = '';

  private reason: string = 'reason1';
  private showError: boolean = false;
  private showSuccess: boolean = false;
  private subscriptionTypeText: string = '';
  private unsubscribedSuccess: boolean = false;
  private reSubscribedSuccess: boolean = false;
  private reSubscribedError: boolean = false;

  private btn_globalUnsubscribe(): void {
    this.isUnsubscribing = true;
    setTimeout(() => {
      this.isUnsubscribing = false;
      this.isPendingToConfirm = false;
    }, 2000);
    const data = { UrlKey: this.$route.query.key, UrlReason: this.urlReason };

    axios
      .post(`/emails/api/v1/global-unsubscribe-add`,
        data,
      )
      .then((response: AxiosResponse<IUnsubscribedData>) => {
        this.unsubscribedSuccess = true;
      })
      .catch((error: AxiosError) => {
        this.unsubscribedSuccess = false;
      });
  }

  private created(): void {
    this.subscriptionType = Configuration.Instance.SubscriptionType;
    this.subscriptionTypeId = parseInt(Configuration.Instance.SubscriptionTypeId, 10);
    this.resourceId = Configuration.Instance.ResourceId;
    this.resourceValue = Configuration.Instance.ResourceValue;
    this.email = Configuration.Instance.Email;
    this.errorMessage = Configuration.Instance.ErrorMessage;
    this.urlKey = Configuration.Instance.UrlKey;
    this.urlReason = Configuration.Instance.UrlReason;

    this.isPendingToConfirm = true;
    this.unsubscribedSuccess = false;
  }

  private btn_reactivateGlobalSubscription(): void {
    this.isResubscribing = true;
    setTimeout(() => {
      this.isResubscribing = false;
      this.isPendingToConfirm = false;
    }, 2000);
    const data = { UrlKey: this.$route.query.key, UrlReason: this.urlReason };

    axios
      .post(`/emails/api/v1/global-unsubscribe-remove`,
        data,
      )
      .then((response: AxiosResponse) => {
        this.reSubscribedSuccess = true;
        this.reSubscribedError = false;
      })
      .catch((reason: AxiosError) => {
        this.reSubscribedSuccess = false;
        this.reSubscribedError = true;
      });
  }

  private focusTextarea(): void {
    const textarea = this.$refs.otherReason as HTMLElement;

    textarea.focus();
  }

  private activateOtherReason(): void {
    this.reason = 'otherReason';
  }

  private fakingErrorSucces(): void {
    if (!this.showError && !this.showSuccess) {
      this.showError = true;
    } else {
      this.showError = !this.showError;
      this.showSuccess = !this.showSuccess;
    }
  }
}
